import { ActionButton, classNamesFunction, styled } from '@fluentui/react';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import React from 'react';

const getStyles = () => {
    return {
        root: {},
        menuLink: {
            padding: "10px",
            "a:-webkit-any-link": {
                textDecoration: "none",
                color: "#ffffff"
            },
            "a:hover": {
                color: "#ffffff"
            }
        },
        menuLinkDiv: {
            "a>:hover": {
                color: "#ffffff"
            }
        },
        menuLinkDiv_Final: {
            ":hover": {
                backgroundColor: "#343437"
            }
        }
    };
}

const getClassNames = classNamesFunction();

function CEAMenuItemComponent({ theme, styles, title, pageRoute, iconName, finalLink }) {

    const classNames = getClassNames(styles, { theme });
    const linkRef = React.useRef(null);
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const onShowContextualMenu = React.useCallback((ev) => {
        ev.preventDefault(); // don't navigate
        setShowContextualMenu(true);
    }, []);
    const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

    let _labelColor = "#000"
    let _classDiv = classNames.menuLinkDiv;

    if (finalLink === "true") {
        _labelColor = "#a3a3a4";
        _classDiv = classNames.menuLinkDiv_Final
    }

    return <div ref={linkRef} className={_classDiv}>
        <ActionButton
            onContextMenu={onShowContextualMenu}
            href={pageRoute}
            iconProps={{ iconName: iconName, style: { color: _labelColor } }}
            style={{ color: _labelColor }}
            onClick={() => window.location.href = pageRoute}>
            {title}
        </ActionButton>
        <ContextualMenu
            items={[
                {
                    key: 'openNewTab',
                    name: `Abrir "${title}" em nova aba`,
                    href: pageRoute,
                    onClick: (ev) => {
                        ev.preventDefault();
                        window.open(pageRoute, "_blank");
                    }
                },
                {
                    key: 'go',
                    text: `Ir para "${title}"`,
                    onClick: (ev) => {
                        ev.preventDefault();
                        window.location.href = pageRoute;
                    }
                }
            ]}
            hidden={!showContextualMenu}
            target={linkRef}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
        />
    </div>

}

export const CEAMenuItem = styled(CEAMenuItemComponent, getStyles);