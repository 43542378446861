import React from 'react';
import { useLocation } from 'react-router-dom';

export default function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>Em construção</h3>
      <p>
        A página <code>{location.pathname}</code> está em construção.
      </p>
    </div>
  );
}

