const formatterMoneyPTbr = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export const formatterNumber2Digits = new Intl.NumberFormat('pt-BR', {
  minimumIntegerDigits: 2,
  useGrouping: false
});

export const trim35 = (nome) => {
  if (nome.length > 35) {
    var result = nome.substring(0, 32);
    return `${result}...`;
  } else {
    return nome;
  }
}

export const fnMoneyPTbrFormatParams = (params) => {
  return formatterMoneyPTbr.format(params.value);
}

export const fnMoneyPTbrFormatValue = (value) => {
  if(value === undefined)
    value = 0;
  return formatterMoneyPTbr.format(value);
}

export const fnNumber2FormatValue = (value) => {
  return formatterNumber2Digits.format(value);
}

export const fnDateTimePTbrFormatParams = (params) => {
  return `${new Date(params.value).toLocaleDateString('pt-BR')} ${new Date(params.value).toLocaleTimeString('pt-BR')}`;
}

export const fnDateTimePTbrFormatValue = (value) => {
  return `${new Date(value).toLocaleDateString('pt-BR')} ${new Date(value).toLocaleTimeString('pt-BR')}`;
}

export const fnDatePTbrFormatParams = (params) => {
  if (params) {
    return `${new Date(params.value).toLocaleDateString('pt-BR')}`;
  }
  return `${new Date("1900-01-01T00:00:00.0000000").toLocaleDateString('pt-BR')}`;
}

export const fnDatePTbrFormatValue = (value) => {
  return `${new Date(value).toLocaleDateString('pt-BR')}`;
}

export const fnDocumentPTbrFormatParams = (params) => {
  return params.value.length === 14 ? RetornaCNPJormatado(params.value) : RetornaCPFFormatado(params.value);
}

export const fnDocumentPTbrFormatValue = (value) => {
  if (value) {
    return value.length === 14 ? RetornaCNPJormatado(value) : RetornaCPFFormatado(value);
  }

  return "";
}

export const RetornaCPFFormatado = (value) => {
  var cpf = value.replace(/[^\d]/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const RetornaDocumentoFormatado = (value) => {
  var doc = value.replace(/[^\d]/g, "");

  if (doc.length === 14) {
    return doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  } else if (doc.length === 11) {
    return doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return doc;
};

export const RetornaCNPJormatado = (value) => {

  if (value === null) {
    return "00.000.000/0000-00";
  }

  var cnpj = value.replace(/[^\d]/g, "");

  if (cnpj.length === 14) {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  return cnpj;
};

export const GetRandomFilename = () => {
  var dia = new Date().getDay();
  var mes = new Date().getMonth();
  var ano = new Date().getFullYear();
  var hh = new Date().getHours();
  var mm = new Date().getMinutes();
  var ss = new Date().getSeconds();

  return `${dia}${mes}${ano}_${hh}${mm}${ss}`;
};


export const headerConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const headerConfigPost = {
  headers: {
    "Content-Type": "application/json",
  },
};