export class CedenteDefault {
    Codigo: string;
    Nome: string;
    Cedente_CNPJ_CPF: string;

    constructor(
        codigo: string,
        nome: string,
        cnpj: string
    ) {
        this.Codigo = codigo;
        this.Nome = nome;
        this.Cedente_CNPJ_CPF =  cnpj;
    }
}