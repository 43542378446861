import { initializeIcons } from '@uifabric/icons';
import React from 'react';
import ReactDOM from 'react-dom';

import { createTheme, loadTheme } from "office-ui-fabric-react";
import App from './App';
import { AuthenticationProvider } from './components/util/authentication';
import { ThemeProvider } from './components/util/theme';
import './index.css';
import * as serviceWorker from './serviceWorker';

initializeIcons();

const nexerTheme = createTheme({
  palette: {
    themePrimary: '#0f62fe',
    themeLighterAlt: '#f7f3fb',
    themeLighter: '#dfd0ef',
    themeLight: '#c5abe2',
    themeTertiary: '#9265c5',
    themeSecondary: '#6931aa',
    themeDarkAlt: '#0353e9',
    themeDark: '#0353e9',
    themeDarker: '#0353e9',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  }
});

loadTheme(nexerTheme)

ReactDOM.render(
  <AuthenticationProvider>
    <ThemeProvider theme={nexerTheme}>
      <App />
    </ThemeProvider>
  </AuthenticationProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
