export class AuthResponse {
    AccessToken!: string;
    ExpiresIn!: number;
    UserToken!: UserToken;

    constructor() {
        this.AccessToken = "";
        this.ExpiresIn = 0;
        this.UserToken = new UserToken();
    }

    public loadData(data: any) {
        this.AccessToken = data.AccessToken;
        this.ExpiresIn = data.ExpiresIn;
        this.UserToken = data.UserToken;
    }

    public loadObject(accessToken: string, expiresIn: number, tokenType: string) {
        this.AccessToken = accessToken;
        this.ExpiresIn = expiresIn;
    }

    public getClaim(type: string): Claims {
        let result = this.UserToken.Claims.find(_ => _.Type === type);
        if (result)
            return result;

        return new Claims();
    }
}

export class UserToken {
    Email!: string;
    Id!: string;
    Claims!: Claims[];
}

export class Claims {
    Type!: string;
    Value!: string;
}