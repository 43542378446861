import { ProgressIndicator, styled } from "@fluentui/react";
import { Dialog, DialogType } from "@fluentui/react/lib/Dialog";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "office-ui-fabric-react/dist/css/fabric.css";
import React, { Suspense } from "react";
import { hot } from "react-hot-loader";
import { BrowserRouter as Router } from "react-router-dom";
import { AutoSwitchLayout } from "./components/layout";
import { renderRoutes } from "./components/util/route";
import routeConfig from "./routeConfig";

function App({ theme }) {
  const { semanticColors } = theme;

  React.useLayoutEffect(() => {
    document.body.style.backgroundColor = semanticColors.bodyBackground;
    document.body.style.color = semanticColors.bodyText;
  }, [semanticColors]);

  return (
    <Router>
      <AutoSwitchLayout>
        <Suspense
          fallback={
            <Dialog
              hidden={false}
              dialogContentProps={{ type: DialogType.normal }}
              modalProps={{ className: "cea-loading", isBlocking: false, dragOptions: false }}>
              <ProgressIndicator label="Por favor aguarde" description="Processando sua requisição ..." />
            </Dialog>
          }
        >
          {renderRoutes(routeConfig)}
        </Suspense>
      </AutoSwitchLayout>
    </Router>
  );
}

// Live reload !== hot reload! CRA doesn't do hot reload, so we install it here.
let HotApp = process.env.NODE_ENV === "production" ? App : hot(module)(App);

export default styled(HotApp);
