import { Customizer, Fabric } from '@fluentui/react';
import React from 'react';
import { useLocalStorage } from 'react-use';

import {
  DarkCustomizations,
  TeamsCustomizations
} from '@uifabric/theme-samples';

import { CEACustomizations } from '../util/CEACustomizations';

export const ThemeList = {
  light: CEACustomizations,
  dark: DarkCustomizations,
  teams: TeamsCustomizations
};

export const ThemeContext = React.createContext({
  theme: 'teams',
});

const ThemeWrapper = ({ children }) => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Customizer {...ThemeList[theme]}>
          <Fabric>{children}</Fabric>
        </Customizer>
      )}
    </ThemeContext.Consumer>
  );
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const changeTheme = name => ThemeList[name] && setTheme(name);
  const value = { theme, changeTheme };
  return (
    <ThemeContext.Provider value={value}>
      <ThemeWrapper>{children}</ThemeWrapper>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
