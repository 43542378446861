// eslint-disable
import { ActionButton, ActivityItem, DefaultButton, Label, Link, Stack, TextField, classNamesFunction, styled } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Icon } from '@fluentui/react/lib/Icon';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Separator } from '@fluentui/react/lib/Separator';
import { IconButton } from 'office-ui-fabric-react';
import {
  DropdownMenuItemType
} from "office-ui-fabric-react/lib/Dropdown";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import cea_logo from '../../../assets/cea-original-b.svg';
import * as CEA from '../../../helpers/Consts';
import { RetornaDocumentoFormatado, trim35 } from '../../../helpers/Format';
import { AuthResponse, CedenteDefault, UserDisplayData } from '../../../models';
import { getPathName } from '../../../routeConfig';
import api from "../../../services/api";
import {
  RetornaFireDoc,
  RetornaPBI,
  getAccessPoint,
  getCalendarFeature,
  getContractGroup,
  getDropEmpresas,
  getFilterPanel,
  getTransactionGroup,
  getUser,
  login,
  setEmpresa,
  setFilterPanel,
  setFilters,
  setFirePoint,
  setPBI,
  setUser
} from "../../../services/auth";
import { useAuthentication } from '../../util/authentication';
import { CEAMenuItem } from '../../util/menuContexto/CEAMenuItem';
import { getCustomSplitButtonStyles } from "./Button.Split.styles";

const reactAppBuildNumber = process.env.REACT_APP_BUILD_NUMBER;
const reactAppCeaEnv = process.env.REACT_APP_CEA_ENV;

const customSplitButtonStyles = getCustomSplitButtonStyles();

let altColor = CEA.CeaEnvironmentAltColor();
let headerColor = CEA.CeaEnvironmentHeaderColor();
let sidebarColor = CEA.CeaEnvironmentHeaderColor();
let hoverColor = CEA.CeaEnvironmentHoverColor();

const getStyles = () => {
  return {
    root: {},
    ProgressNotify: {
      marginTop: "0px !important",
      marginBottom: "0px !important",
      backgroundColor: "transparent !important",
      alignSelf: "end"
    },
    SuiteNavWrapper: {
      display: "block"
    },
    SuiteNavPlaceholder: {
      minHeight: "29px"
    },
    O365_NavHeader: {
      minHeight: "40px", height: "40px", lineHeight: "40px", minLineHeight: "29px", backgroundPosition: "left top", backgroundRepeat: "repeat", width: "100%", display: "flex", borderSpacing: 0, whiteSpace: "nowrap"
    },
    O365_HeaderLeftRegion: {
      alignItems: "center", justifyContent: "space-between", margin: 0, padding: 0, textAlign: "center", height: "100%", width: "0px"
    },
    _26VD_pe1GeiveQieoZ7ZgV: {
      height: "100%", lineHeight: "40px", width: "40px", lineWidth: "40px", display: "flex"
    },
    menuLink: {
      padding: "10px",
      "a:-webkit-any-link": {
        textDecoration: "none",
        color: "rgb(50, 49, 48)"
      },
      "a:hover": {
        color: "#0F62FE"
      }
    },
    contentRegion: {
      backgroundColor: "#F3F2F1",
      maxHeigth: "100vh - 40px",
      overflowY: 'hidden',
      height: "100vh - 40px",
      '.root-135': {
        maxHeigth: "100vh - 40px",
        overflowY: "hidden",
        height: "100vh - 40px",
      }
    },
    contentWrapper: {
    },
    o365cs_base: {
      color: "#FFFFFF",
      backgroundColor: "#004B8B",
      lineHeight: "40px",
      lineWidth: "40px",
      width: "40px",
      background: "transparent",
      bordeWwidth: 0,
      height: "100%",
      boxSizing: "border-box",
      overflow: "visible",
      borderRadius: "0px",
      '&:hover': {
        color: "#FFFFFF",
        backgroundColor: hoverColor
      },
      '&:active': {
        color: "#FFFFFF",
        backgroundColor: "#030303"
      }
    },
    centerRegion: {
      width: '100%'
    },
    fullWidth: {
      width: "100%",

    },
    posCenterRegion: {
      display: "block",
      position: "relative"
    },
    topLevelRegion: {
      height: "40px",
      minHeight: "29px",
      display: "flex",
      paddingRight: "15px !important"
    },
    leftRegion: {
      height: "40px",
      minHeight: "29px",
      display: "flex",
      justifyContent: "flex-start"
    },
    O365_SuiteBranding_container: {
      height: "100%",
      lineHeight: "40px",
      display: "flex"
    },
    o365sx_appName: {
      color: "#FFFFFF",
      backgroundColor: headerColor,
      height: "100%",
      display: "flex",
      verticalAlign: "middle",
      outlineOffset: "-1px",
      padding: "0 12px 0 8px",
      textDecoration: "none"
    },
    m_YdpO5_33Q22e4K9wQQN: {
      display: "flex",
      textDecoration: "none",
      color: "inherit",
      '&:hover': {
        color: "#FFFFFF",
      },
    },
    _6EkCIBulssv0eDQ55G3yH: {
      verticalAlign: "top",
      display: "inline-block",
      fontWeight: 600,
      fontFamily: "SegoeUI-SemiBold-final,Segoe UI Semibold,SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
      fontSize: "16px",
      lineHeight: "40px",
      minLineHeight: "29px",
      '&:hover': {
        color: "#FFFFFF",
      }
    },
    wfFdvWFDB5g8R2lHFkXD1: {
      height: "40px",
      display: "flex",
      flex: "1 0 auto",
      justifyContent: "center"
    },
    aCenterRegion: {
      display: "flex",
      flex: "1 0 auto"
    },
    gz3Kx7T9vlYJGi_2BSnXs: {
      height: "100%",
      lineHeight: "40px",
      display: "none",
      flex: "1 0 auto",
      justifyContent: "center",
      minWidth: "408px"
    },
    aSearchContainer: {
      display: "inherit",
      flex: "inherit",
      minWidth: "inherit",
      justifyContent: "inherit"
    },
    empresaNome: {
      color: "#ffffff",
      verticalAlign: "top",
      display: "inline-block",
      fontWeight: 600,
      fontFamily: "SegoeUI-SemiBold-final,Segoe UI Semibold,SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
      fontSize: "14px",
      lineHeight: "40px"
    },
    empresaNomeInput: {
      fontFamily: "inherit",
      fontSize: "14px",
      flex: "1 0 0px",
      width: "100%",
      color: `${headerColor} !important`,
      backgroundColor: "#fff",
      borderRadius: "0px !important",
      border: "none !important",
      padding: 0,
      height: "100%",
      outline: "none",
      '.ms-TextField-fieldGroup': {
        height: "4vh",
        border: "none !important;",
        color: "#000000 !important",
        backgroundColor: "#fff !important",
        input: {
          color: "#000000 !important",
        }
      },
      '&:focus': {
        borderRadius: "0px !important",
        border: "none !important",
      }
    },
    empresaSelect: {
      color: "rgb(5, 56, 112)",
      fontSize: "16px",
    },
    sbcId: {
      boxShadow: "0 2px 4px -0.75px rgba(0,0,0,.1)",
      color: "#252423",
      backgroundColor: "#fff",
      maxWidth: "468px",
      width: "100%",
      margin: "0px 44px",
      top: "5px",
      borderRadius: "3px",
      height: "80%",
      textAlign: "left",
      position: "relative",
      fontFamily: "SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
    },
    formHeaderSearch: {
      boxSizing: "content-box",
      display: "flex",
      height: "100%",
      borderRadius: "2px"
    },
    magnifierButton_330: {
      boxSizing: "border-box",
      display: "inline-block",
      border: "none",
      backgroundImage: "initial",
      backgroundPosition: "initial",
      backgroundSize: "initial",
      backgroundRepeat: "initial",
      backgroundAttachment: "initial",
      backgroundOrigin: "initial",
      backgroundClip: "initial",
      outline: "none",
      verticalAlign: "top",
      fontSize: "16px",
      textAlign: "center",
      cursor: "text",
      transition: "padding-right 0.167s cubic-bezier(0.1, 0.9, 0.2, 1) 0s",
      backgroundColor: "inherit",
      paddingLeft: "12px",
      paddingRight: "8px",
      width: "44px",
      minWidth: "44px",
      maxWidth: "44px",
      flex: "0 0 44px",
      borderRadius: "2px 0px 0px 2px",
      height: "100% !important",
      //

    },
    newMagButton: {
      width: "3.5vh",
      height: "3.5vh",
      display: "flex"
    },
    butIcon331: {
      fontSize: "16px",
      textAlign: "center",
      cursor: "text",
      display: "inline-flex",
      alignSelf: "center",
      position: "relative",
      height: "1em",
      width: "1em",
      transition: "color 0.167s cubic-bezier(0.1, 0.9, 0.2, 1) 0s",
      color: "rgb(5, 56, 112)",
      fill: "rgb(5, 56, 112)",
    },
    butIconDropEmpresa: {
      fontSize: "16px",
      textAlign: "center",
      cursor: "pointer",
      display: "inline-flex",
      alignSelf: "center",
      position: "relative",
      height: "1em",
      width: "1em",
      color: "rgb(5, 56, 112)",
      fill: "rgb(5, 56, 112)",
    },
    HeaderButtonRegion: {
      flex: "1 0 auto",
      justifyContent: "flex-end",
      height: "40px",
      display: "flex",
      maxWidth: "150px"
    },
    O365_Feature_Flags_container: {
      height: "100%",
      lineHeight: "40px",
      display: "flex"
    },
    O365_MainLink_Day_container: {
      height: "100%",
      lineHeight: "40px",
      display: "flex"
    },
    O365_MainLink_Chat_container: {
      height: "100%",
      lineHeight: "40px",
      display: "flex"
    },
    O365_MainLink_Bell_container: {
      height: "100%",
      lineHeight: "40px",
      display: "flex"
    },
    O365_MainLink_Settings_container: {
      height: "100%",
      lineHeight: "40px",
      display: "flex"
    },
    O365_MainLink_Help_container: {
      height: "100%",
      lineHeight: "40px",
      display: "flex",
      '.ms-Button-menuIcon': {
        display: "none"
      },
      '&:hover': {
        color: "#FFFFFF",
        backgroundColor: "transparent",
        fontWeight: 'bold'
      },
      '.&:hover': {
        color: "#FFFFFF",
        backgroundColor: "transparent",
        fontWeight: 'bold'
      }
    },
    O365_HeaderButton: {
      color: "#0f62fe",
      backgroundColor: headerColor,
      lineHeight: "40px",
      background: "transparent",
      borderWidth: 0,
      width: "auto",
      height: "100%",
      boxSizing: "border-box",
      overflow: "visible",
      display: "block",
      position: "relative",
      margin: 0,
      padding: 0,
      minWidth: "40px",
      textAlign: "center",
      cursor: "pointer",
      outlineOffset: "-1px",
      borderRadius: "0px",
      '&:hover': {
        color: "#0f62fe",
        backgroundColor: hoverColor
      },
      '&:active': {
        color: "#0f62fe",
        backgroundColor: hoverColor
      },
      'span > i': {
        color: "#0f62fe !important",
      }
    },
    O365_HeaderRightRegion: {
      color: "#FFFFFF",
      backgroundColor: headerColor,
      display: "flex",
      flex: "0 0 auto",
    },
    buttonHeaderVersion: {
      padding: "0px !important",
      color: "#FFFFFF",
      backgroundColor: headerColor,
      lineHeight: "40px",
      borderWidth: 0,
      width: "auto",
      height: "100%",
      boxSizing: "border-box",
      overflow: "visible",
      '.ms-Button-menuIcon': {
        display: "none"
      }
    },
    O365_MainLink_Release: {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: 10,
      fontSize: "6px"
    },
    buttonHeaderRightUser: {
      padding: "0px !important",
      color: "#FFFFFF",
      backgroundColor: headerColor,
      lineHeight: "40px",
      borderWidth: 0,
      width: "auto",
      height: "100%",
      boxSizing: "border-box",
      overflow: "visible",
      '.ms-Button-menuIcon': {
        display: "none"
      },
      '&:hover': {
        color: "#FFFFFF",
        backgroundColor: "transparent",
        fontWeight: 'bold'
      },
      '.&:hover': {
        color: "#FFFFFF",
        backgroundColor: "transparent",
        fontWeight: 'bold'
      }
    },
    O365_MainLink_MePhoto: {
      //width: "32px",
      //height: "32px",
      overflow: "hidden",
      display: "inline-block",
      //borderRadius: "50%",
      verticalAlign: "middle",
      marginRight: '1px',
      fontSize: "6px",
      '&:hover': {
        color: "#FFFFFF",
        backgroundColor: hoverColor,
      }
    },
    meInitialsButton: {
      backgroundColor: "transparent!important",
      color: "inherit!important",
      boxSizing: "border-box",
      fontSize: "12px",
      fontFamily: "SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
      lineHeight: "normal!important",
      // display: "flex",
      // left: 0,
      top: 0,
      textAlign: "right",
      width: "100%",
      height: "100%",
      '&:hover': {
        color: "#FFFFFF",
        backgroundColor: hoverColor,
      }
    },
    meCedenteButton: {
      backgroundColor: "transparent!important",
      color: "inherit!important",
      boxSizing: "border-box",
      fontSize: "1.2vh",
      fontFamily: "SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
      lineHeight: "normal!important",
      // display: "flex",
      // left: 0,
      top: 0,
      textAlign: "right",
      width: "100%",
      height: "100%",
      '&:hover': {
        color: "#FFFFFF",
        backgroundColor: hoverColor,
      }
    },
    meVersionButton: {
      backgroundColor: "transparent!important",
      color: "inherit!important",
      boxSizing: "border-box",
      fontSize: "max(9px, 1.2vh)",
      fontFamily: "SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
      lineHeight: "normal!important",
      display: "flex",
      left: 0,
      top: 0,
      textAlign: "left",
      width: "100%",
      height: "100%",
      '&:hover': {
        color: "#FFFFFF",
        backgroundColor: hoverColor,
      }
    },
    fxs_avatarmenu_tenant_image_container: {
      height: "28px",
      width: "28px",
      flex: "0 0 auto",
      paddingLeft: "6px",
      paddingRight: "6px",
      boxSizing: "content-box"
    },
    fxs_avatarmenu_tenant_image: {
      height: "28px",
      width: "28px",
      border: "0",
      borderRadius: "28px"
    },
    exampleRoot: {
      marginTop: '20px',
    },
    nameText: {
      fontWeight: 'bold',
    },
  };
};

const getClassNames = classNamesFunction();

MasterLayoutComponent.propTypes = {
  children: PropTypes.any.isRequired,
  theme: PropTypes.any.isRequired,
  styles: PropTypes.any.isRequired,
}
function MasterLayoutComponent({ children, theme, styles }) {
  const theLocation = useLocation();
  const currentLocation = theLocation.pathname;

  React.useEffect(() => {
    document.title = `CEA${CEA.CeaEnvironment()} | ${getPathName(currentLocation)}`;
}, [currentLocation])

  const initialValue = [
    {
      key: "0",
      text: "0",
      code: "0000",
      groupId: "0000",
      itemType: 0,
      isSelected: false
    }];

  const [painelEmpresas, setPainelEmpresas] = useState(false);
  const [painelNotificacoes, setPainelNotificacoes] = useState(false);
  const [cedenteOptions, setCedentesValues] = useState(initialValue);
  //
  const [pushs, setPushs] = useState([]);
  const [isTransactionGroup, { setTrue: setTransactionGroup, setFalse: unsetTransactionGroup }] = useBoolean(false);
  const [isCalendarFeature, { setTrue: setCalendarFeature, setFalse: unsetCalendarFeature }] = useBoolean(false);
  const [isContractGroup, { setTrue: setContractGroup, setFalse: unsetContractGroup }] = useBoolean(false);
  const [sidebarOpen, { setTrue: openSidebar, setFalse: closeSidebar }] = useBoolean(true);
  const [isOpenTarefas, { setTrue: openPanelTarefas, setFalse: dismissPanelTarefas }] = useBoolean(false);
  const [isOpenRelatorios, { setTrue: openPanelRelatorios, setFalse: dismissPanelRelatorios }] = useBoolean(false);
  const [isOpenTitulos, { setTrue: openPanelTitulos, setFalse: dismissPanelTitulos }] = useBoolean(false);
  const [isOpenClientes, { setTrue: openPanelClientes, setFalse: dismissPanelClientes }] = useBoolean(false);
  const [isOpenSistema, { setTrue: openPanelSistema, setFalse: dismissPanelSistema }] = useBoolean(false);
  const [isOpenContratos, { setTrue: openPanelContratos, setFalse: dismissPanelContratos }] = useBoolean(false);
  const [isOpenConfig, { setTrue: openPanelConfig, setFalse: dismissPanelConfig }] = useBoolean(false);
  const [isOpenAdmin, { setTrue: openPanelAdmin, setFalse: dismissPanelAdmin }] = useBoolean(false);


  const classNames = getClassNames(styles, { theme });
  const { logout } = useAuthentication();
  let defaultCedente = new CedenteDefault("NH", "NH");
  let dropEmpresas = [];
  let dropEmpresasFix = [];
  const cedentes = getDropEmpresas();
  const accessPoint = getAccessPoint();

  useEffect(() => {
    const transactionGroup = getTransactionGroup();
    const contractGroup = getContractGroup();
    const calendarFeature = getCalendarFeature();
    const consultFilterPanel = getFilterPanel();
    transactionGroup ? setTransactionGroup() : unsetTransactionGroup();
    contractGroup ? setContractGroup() : unsetContractGroup();
    calendarFeature ? setCalendarFeature() : unsetCalendarFeature();
    if (consultFilterPanel === undefined || consultFilterPanel === null) {
      setFilterPanel(true);
    } else {
      setFilterPanel(consultFilterPanel);
    }
  }, [setCalendarFeature, setContractGroup, setTransactionGroup, unsetCalendarFeature,
    unsetContractGroup, unsetTransactionGroup]);

  var i = cedentes.filter((cd) => cd.Cedente_CNPJ_CPF === accessPoint.toString())[0];
  defaultCedente = new CedenteDefault(i.Codigo, i.Nome, i.Cedente_CNPJ_CPF);

  let grupos = Array.from(new Set(cedentes.map(({ GrupoDeCedente_ID }) => ({ GrupoDeCedente_ID })).map(s => s.GrupoDeCedente_ID)))
    .map(GrupoDeCedente_ID => {
      return {
        GrupoDeCedente_ID: GrupoDeCedente_ID,
        GrupoDeCedente_Nome: cedentes.find(s => s.GrupoDeCedente_ID === GrupoDeCedente_ID).GrupoDeCedente_Nome,
      };
    });

  let divider = 1;
  let tGrupos = grupos.length;

  grupos.forEach((grupo) => {

    const dropHeader = {
      key: grupo.GrupoDeCedente_ID,
      text: grupo.GrupoDeCedente_Nome,
      itemType: DropdownMenuItemType.Header,
      isSelected: false
    };

    dropEmpresas.push(dropHeader);

    var itens = cedentes.filter((cd) => cd.GrupoDeCedente_ID === grupo.GrupoDeCedente_ID);

    itens.forEach(cd => {
      var dropItem = {
        key: cd.Cedente_CNPJ_CPF,
        text: `${cd.Nome}`,
        code: `${cd.Codigo}`,
        groupId: cd.GrupoDeCedente_ID,
        groupName: cd.GrupoDeCedente_Nome,
        itemType: DropdownMenuItemType.Normal,
        isSelected: false
      };

      dropEmpresas.push(dropItem);

    });

    if (grupos.length > 1 & divider !== tGrupos) {

      var dropDivider = {
        key: `divider_${divider}`,
        text: '-',
        itemType: DropdownMenuItemType.Divider,
        isSelected: false
      };
      dropEmpresas.push(dropDivider);
    }
    divider = divider + 1;
  });

  dropEmpresas.forEach((element) => {

    if (accessPoint === Number.parseInt(element.key)) {
      element.isSelected = true;
    } else {
      element.isSelected = false;
    }
  });

  dropEmpresasFix = dropEmpresas;

  useEffect(() => {
    setCedentesValues(dropEmpresas);
  }, []);

  const history = useHistory();

  const goToPage = (path) => {
    dismissPanelTarefas();
    dismissPanelTitulos();
    dismissPanelSistema();
    dismissPanelConfig();
    dismissPanelAdmin();
    history.push(path);
  }

  const caching = () => {
    let version = localStorage.getItem('version');
    if (version !== process.env.REACT_APP_BUILD_NUMBER) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach(name => {
            caches.delete(name);
          })
        });
        window.location.reload(true);
      }

      localStorage.removeItem('version');
      localStorage.setItem('version', process.env.REACT_APP_BUILD_NUMBER);
    }
  };

  caching();

  return (
    <div style={{ backgroundColor: headerColor }}>
      <div style={{ backgroundColor: headerColor }} className={"o365header " + (sidebarOpen ? 'cea-mainMenuHeader' : 'cea-mainFullHeader')}>
        <div id="SuiteNavWrapper" className={classNames.SuiteNavWrapper}>
          <div id="SuiteNavPlaceholder" className={classNames.SuiteNavPlaceholder}>
            <div id="O365_NavHeader" className={classNames.O365_NavHeader}>
              <div id="O365_HeaderLeftRegion" className={classNames.O365_HeaderLeftRegion}>
                <div id="_26VD_pe1GeiveQieoZ7ZgV" className={classNames._26VD_pe1GeiveQieoZ7ZgV}>

                </div>
              </div>
              <div id="CenterRegion" className={classNames.centerRegion}>
                <div className={classNames.fullWidth}>
                  <div className={classNames.posCenterRegion}>
                    <div id="topLevelRegion" className={classNames.topLevelRegion}>
                      <div id="leftRegion" className={classNames.leftRegion}>
                        <div id="O365_SuiteBranding_container" className={classNames.O365_SuiteBranding_container} >
                          <div>
                            <div style={{ marginTop: 3, maxHeight: 37, marginLeft: 10 }}>
                              <IconButton
                                className={'btnHeaderShortcut' + CEA.CeaEnvironmentClass()}
                                iconProps={{ iconName: 'GlobalNavButton' }}
                                style={{ color: "#fff", height: 29 }}
                                title="Recolher/Expandir menu"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  if (sidebarOpen) {
                                    closeSidebar();
                                  } else {
                                    openSidebar();
                                  }
                                }}
                                ariaLabel="Recolher/Expandir menu" />
                            </div>
                          </div>
                          <span><i style={CEA.iconMenuHeader} className={`ms-Icon ms-Icon--Separator`} aria-hidden="true"></i></span>
                          <div>
                            <div style={{ marginTop: 3, maxHeight: 37, display: 'flex' }}>
                              {getUser().Sigma !== 'ae77045b-bffa-43eb-9760-0b616b9dec11' && <div>
                                <IconButton
                                  className={'btnHeaderShortcut' + CEA.CeaEnvironmentClass()}
                                  iconProps={{ iconName: 'Dictionary' }}
                                  style={{ color: "#fff", height: 29 }}
                                  title="Diários de registro e renegociação"
                                  onClick={() => goToPage("/tarefas/diarios")}
                                  ariaLabel="Diários de registro e renegociação" />

                                <IconButton
                                  className={'btnHeaderShortcut' + CEA.CeaEnvironmentClass()}
                                  iconProps={{ iconName: 'TaskManager' }}
                                  style={{ color: "#fff", height: 29 }}
                                  title="Diários lançados"
                                  onClick={() => goToPage("/sistema/processamentos")}
                                  ariaLabel="Diários lançados (Fila de processos)" />

                                <IconButton
                                  className={'btnHeaderShortcut' + CEA.CeaEnvironmentClass()}
                                  iconProps={{ iconName: 'DocumentReply' }}
                                  style={{ color: "#fff", height: 29 }}
                                  title="Arquivos de retorno (CNAB)"
                                  onClick={() => goToPage("/tarefas/retornos")}
                                  ariaLabel="Arquivos de retorno (CNAB)" />

                              </div>}

                              <IconButton
                                className={'btnHeaderShortcut' + CEA.CeaEnvironmentClass()}
                                iconProps={{ iconName: 'DocumentSearch' }}
                                style={{ color: "#fff", height: 29, marginTop: 3 }}
                                title="Consulta de títulos (Geral)"
                                onClick={() => goToPage("/consulta/titulos=all_geral_0")}
                                ariaLabel="Consulta de títulos (Geral)" />


                            </div>
                          </div>
                          {getUser().Sigma === '0f324aad-6589-4ac1-8e9c-15ae7233c072' &&
                            <span><i style={CEA.iconMenuHeader} className={`ms-Icon ms-Icon--Separator`} aria-hidden="true"></i></span>}
                          {getUser().Sigma === '0f324aad-6589-4ac1-8e9c-15ae7233c072' && <div>

                            <div style={{ marginTop: 3, maxHeight: 37, display: 'flex' }}>
                              <IconButton
                                className={'btnHeaderShortcut' + CEA.CeaEnvironmentClass()}
                                iconProps={{ iconName: 'Telemarketer' }}
                                style={{ color: "#fff", height: 29, marginTop: 3 }}
                                title="Suporte"
                                onClick={() => goToPage("/suporte/consulta")}
                                ariaLabel="Suporte" />
                              <IconButton
                                className={'btnHeaderShortcut' + CEA.CeaEnvironmentClass()}
                                iconProps={{ iconName: 'AdminALogo32' }}
                                style={{ color: "#fff", height: 29, marginTop: 3 }}
                                title="Administração"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  openPanelAdmin(true);
                                }}
                                ariaLabel="Administração" />
                            </div>
                          </div>}
                          {getUser().Sigma === 'f2ec6d70-1d1f-4bcc-b81f-4caa2646c797' &&
                            <span><i style={CEA.iconMenuHeader} className={`ms-Icon ms-Icon--Separator`} aria-hidden="true"></i></span>}
                          {getUser().Sigma === 'f2ec6d70-1d1f-4bcc-b81f-4caa2646c797' && <div>
                            <div style={{ marginTop: 3, maxHeight: 37, display: 'flex' }}>
                              <IconButton
                                className={'btnHeaderShortcut' + CEA.CeaEnvironmentClass()}
                                iconProps={{ iconName: 'Telemarketer' }}
                                style={{ color: "#fff", height: 29, marginTop: 3 }}
                                title="Suporte"
                                onClick={() => goToPage("/suporte/consulta")}
                                ariaLabel="Suporte" />
                            </div>
                          </div>}
                        </div>
                      </div>
                      <div id="centerRegion" className={classNames.wfFdvWFDB5g8R2lHFkXD1}>
                        <div className={classNames.aCenterRegion}>
                          <div style={{ width: "8px", minWidth: "8px" }}></div>
                          <div id="O365_SearchBoxContainer_container" className={classNames.gz3Kx7T9vlYJGi_2BSnXs}>
                            <div id="aSearchContainer" className={classNames.aSearchContainer}>
                              <div id="sbcId" className={classNames.sbcId}>
                                <div id="formHeaderSearch" className={classNames.formHeaderSearch}>
                                  <div>
                                    <div id="magBut" className={classNames.newMagButton}>
                                      <IconButton className={classNames.butIcon331} iconProps={{ iconName: 'CityNext2' }} title="Home" ariaLabel="Home" />
                                    </div>
                                  </div>
                                  <TextField defaultValue={`${defaultCedente.Codigo} - ${defaultCedente.Nome}`} className={classNames.empresaNomeInput} />
                                  <div>
                                    <div id="magBut2" className={classNames.newMagButton}>
                                      <IconButton
                                        className={classNames.empresaSelect}
                                        disabled={false}
                                        checked={false}
                                        split={true}
                                        aria-roledescription={"split button"}
                                        styles={customSplitButtonStyles}
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        menuProps={{
                                          items: dropEmpresas,
                                          onItemClick: async (event, option) => {
                                            var res = await api.get(
                                              "cedente/lista/cnpj?cnpj=" + option.key
                                            );
                                            setEmpresa(res.data);
                                            document.location.reload();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="HeaderButtonRegion" className={classNames.HeaderButtonRegion}>
                        <div id="O365_Feature_Flags_container" className={classNames.O365_Feature_Flags_container}></div>
                        <div id="O365_MainLink_Day_container" className={classNames.O365_MainLink_Day_container}></div>
                        <div id="O365_MainLink_Chat_container" className={classNames.O365_MainLink_Chat_container}></div>
                        <div id="O365_HeaderRightRegion" className={classNames.O365_HeaderRightRegion}>
                          <div style={{
                            height: "100%",
                            lineHeight: "40px",
                            display: "flex"
                          }}>
                            <span className={classNames.buttonHeaderVersion}>
                              <div style={{
                                height: "100%",
                                maxWidth: "176px",
                                display: "flex"
                              }}>
                                <div style={{
                                  textAlign: "left",
                                  display: "block",
                                  overflow: "hidden",
                                  maxWidth: "160px",
                                  fontSize: "13px",
                                  fontFamily: "SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
                                  textOverflow: "ellipsis",
                                  wordBreak: "break-all",
                                  whiteSpace: "nowrap",
                                  lineHeight: "40px"
                                }}>
                                  <span style={{ display: "none" }}>Label</span>
                                </div>
                                <div style={{
                                  borderLeftWidth: "0!important",
                                  verticalAlign: "middle",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  background: "transparent",
                                  borderWidth: 0,
                                  width: "auto",
                                  height: "100%",
                                  boxSizing: "border-box",
                                  overflow: "visible",
                                  position: "relative",
                                  margin: 0,
                                  padding: 0,
                                  minWidth: "40px",
                                  textAlign: "center",
                                  outlineOffset: "-1px"
                                }}>
                                  <div id="O365_MainLink_Release" className={classNames.O365_MainLink_Release}>
                                    <div>
                                      <div style={{ height: "100%" }}>
                                        <div>
                                          <div style={{
                                            position: "relative",
                                            overflow: "hidden",
                                            fontSize: "10px !important",
                                            fontWeight: 500,
                                            color: altColor
                                          }}>
                                            <div id="meInitialsButton" className={classNames.meVersionButton}>
                                              Build {reactAppBuildNumber}
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div id="O365_MainLink_Help_container" className={classNames.O365_MainLink_Help_container}>

                          <div style={{ height: "100%", display: "grid" }}>

                            <IconButton
                              className={classNames.O365_HeaderButton}
                              iconProps={{ iconName: 'Ringer' }}
                              title="Notificações"
                              ariaLabel="Notificações"
                              onClick={async () => {
                                setPainelNotificacoes(true);
                                await api.get('activity/logs').then((result) => {
                                  setPushs(result.data);
                                })
                              }} />

                          </div>
                        </div>
                        <DefaultButton className={classNames.buttonHeaderRightUser}
                          onClick={(ev) => { ev.preventDefault(); setPainelEmpresas(true) }}>
                          <div style={{
                            height: "100%",
                            maxWidth: "176px",
                            display: "flex",
                            '&:hover': {
                              color: "#FFFFFF",
                              backgroundColor: hoverColor,
                            }
                          }}>
                            <div style={{
                              textAlign: "left",
                              display: "block",
                              overflow: "hidden",
                              maxWidth: "160px",
                              fontSize: "13px",
                              fontFamily: "SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
                              textOverflow: "ellipsis",
                              wordBreak: "break-all",
                              whiteSpace: "nowrap",
                              lineHeight: "40px",
                              '&:hover': {
                                color: "#FFFFFF",
                                backgroundColor: hoverColor,
                              }
                            }}>
                              <span style={{ display: "none" }}>User</span>
                            </div>
                            <div style={{
                              borderLeftWidth: "0!important",
                              verticalAlign: "middle",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              background: "transparent",
                              borderWidth: 0,
                              width: "auto",
                              height: "100%",
                              boxSizing: "border-box",
                              overflow: "visible",
                              position: "relative",
                              margin: 0,
                              padding: 0,
                              minWidth: "40px",
                              textAlign: "center",
                              cursor: "pointer",
                              outlineOffset: "-1px",
                              '&:hover': {
                                color: "#FFFFFF",
                                backgroundColor: hoverColor,
                              }
                            }}>
                              <div id="O365_MainLink_MePhoto" className={classNames.O365_MainLink_MePhoto}>
                                <div>
                                  <div style={{ height: "100%" }}>
                                    <div>
                                      <div style={{
                                        //width: "32px",
                                        //height: "32px",
                                        position: "relative",
                                        overflow: "hidden",
                                        fontSize: "10px !important",
                                        '&:hover': {
                                          color: "#FFFFFF",
                                          backgroundColor: hoverColor,
                                        }
                                      }}>
                                        <div id="meInitialsButton" className={classNames.meInitialsButton}>
                                          {defaultCedente.Codigo}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </DefaultButton>
                        <DefaultButton className={classNames.buttonHeaderRightUser}
                          onClick={(ev) => { ev.preventDefault(); setPainelEmpresas(true) }}>
                          <div style={{
                            height: "100%",
                            maxWidth: "176px",
                            display: "flex",
                            '&:hover': {
                              color: "#FFFFFF",
                              backgroundColor: hoverColor,
                            }
                          }}>
                            <div style={{
                              textAlign: "left",
                              display: "block",
                              overflow: "hidden",
                              maxWidth: "160px",
                              fontSize: "13px",
                              fontFamily: "SegoeUI-Regular-final,Segoe UI,'Segoe UI Web (West European)',Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif",
                              textOverflow: "ellipsis",
                              wordBreak: "break-all",
                              whiteSpace: "nowrap",
                              lineHeight: "40px",
                              '&:hover': {
                                color: "#FFFFFF",
                                backgroundColor: hoverColor,
                              }
                            }}>
                              <span style={{ display: "none" }}>User</span>
                            </div>
                            <div style={{
                              borderLeftWidth: "0!important",
                              verticalAlign: "middle",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              background: "transparent",
                              borderWidth: 0,
                              width: "auto",
                              height: "100%",
                              boxSizing: "border-box",
                              overflow: "visible",
                              position: "relative",
                              margin: 0,
                              padding: 0,
                              minWidth: "40px",
                              textAlign: "center",
                              cursor: "pointer",
                              outlineOffset: "-1px",
                              '&:hover': {
                                color: "#FFFFFF",
                                backgroundColor: hoverColor,
                              }
                            }}>
                              <div id="O365_MainLink_MePhoto" className={classNames.O365_MainLink_MePhoto}>
                                <div>
                                  <div style={{ height: "100%" }}>
                                    <div>
                                      <div style={{
                                        //width: "32px",
                                        //height: "32px",
                                        position: "relative",
                                        overflow: "hidden",
                                        fontSize: "10px !important",
                                        //borderRadius: "50%",
                                        '&:hover': {
                                          color: "#FFFFFF",
                                          backgroundColor: hoverColor,
                                        }
                                      }}>
                                        <div id="meInitialsButton" className={classNames.meInitialsButton}>
                                          {CEA.displayUsuario()}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </DefaultButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>



      <Stack horizontal className={classNames.root}>
        <Stack.Item style={{ backgroundColor: headerColor }} grow={false} className={"cea-mainSidebar " + (sidebarOpen ? "" : 'cea-hidden')}>
          <div style={{ backgroundColor: sidebarColor, width: "112px" }}>

            <div>
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <img style={{ width: '5rem' }} src={cea_logo} alt="CEA" />
              </div>
              {reactAppCeaEnv.includes("UAT") && <div style={{ justifyContent: 'center', display: 'flex' }}>
                <span style={{ color: 'red', fontSize: 10 }}>UAT</span>
              </div>}
              {reactAppCeaEnv.includes("Dev") && <div style={{ justifyContent: 'center', display: 'flex' }}>
                <span style={{ color: 'yellow', fontSize: 10 }}>DEV</span>
              </div>}
            </div>

            <div style={{ marginTop: 16 }}>
              <div className='cea-sidebarNav' onClick={(ev) => {
                ev.preventDefault();
                goToPage("/");
              }}>
                <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                  <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'Home' }} allowDisabledFocus>
                    Home
                  </ActionButton>
                </div>
              </div>
            </div>

            {getUser().Sigma === 'ae77045b-bffa-43eb-9760-0b616b9dec11' &&
              <div>
                <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    goToPage('/consulta/titulos=all_geral_0');
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'DocumentSearch' }} allowDisabledFocus>
                        Consulta Títulos
                      </ActionButton>
                    </div>
                  </div>
                </div>
              </div>
            }
            {getUser().Sigma === 'ae77045b-bffa-43eb-9760-0b616b9dec11' &&
              <div>
                <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    goToPage('/sistema/logs-email');
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'MailSchedule' }} allowDisabledFocus>
                        Logs E-mail
                      </ActionButton>
                    </div>
                  </div>
                </div>
              </div>
            }
            {getUser().Sigma !== 'ae77045b-bffa-43eb-9760-0b616b9dec11' &&
              <div>
                <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    openPanelTarefas(true);
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'TaskSolid' }} allowDisabledFocus>
                        Tarefas
                      </ActionButton>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    openPanelTitulos(true);
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'SearchData' }} allowDisabledFocus>
                        Consultas
                      </ActionButton>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    openPanelClientes(true);
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'AccountBrowser' }} allowDisabledFocus>
                        Clientes
                      </ActionButton>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    openPanelRelatorios(true);
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'ReportDocument' }} allowDisabledFocus>
                        Relatórios
                      </ActionButton>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    openPanelSistema(true);
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'System' }} allowDisabledFocus>
                        Sistema
                      </ActionButton>
                    </div>
                  </div>
                </div>

                {isContractGroup && <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    openPanelContratos(true);
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'Certificate' }} allowDisabledFocus>
                        Contratos
                      </ActionButton>
                    </div>
                  </div>
                </div>}

                <div>
                  <div className='cea-sidebarNav' onClick={(ev) => {
                    ev.preventDefault();
                    openPanelConfig(true);
                  }}>
                    <div className={'cea-sidebarItem' + CEA.CeaEnvironmentClass()}>
                      <ActionButton className="cea-sidebarButton" iconProps={{ iconName: 'Settings' }} allowDisabledFocus>
                        Configurações
                      </ActionButton>
                    </div>
                  </div>
                </div>
              </div>}
          </div>

          <Panel
            isOpen={isOpenTarefas}
            type={PanelType.smallFixedNear}
            onDismiss={dismissPanelTarefas}
            isLightDismiss={true}
            isBlocking={true}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText=""
            className="cea-menu-panel"
            style={{ left: "112px", top: "40px", }}
            styles={{
              main: {
                width: "280px",
                backgroundColor: headerColor,
                boxShadow: "none !important",
                borderRight: "1px solid #cabdbd",
                borderLeft: "1px solid rgb(46 46 46)",
                contentInner: {
                  scrollableContent: {
                    content: {
                      paddingLeft: "0px !important",
                      paddingRight: "0px !important"
                    }
                  }
                },
                ".ms-Panel-content": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important"
                },
                ".ms-Panel-commands": {
                  marginTop: "0px",
                  backgroundColor: headerColor,
                },
              }
            }}>
            <Stack vertical>
              <Label className='text-white'>Gerar arquivos</Label>
              <CEAMenuItem title="Diários" pageRoute="/tarefas/diarios" iconName="Dictionary" finalLink="true" />
              <CEAMenuItem title="Diários lançados" pageRoute="/sistema/processamentos" iconName="TaskManager" finalLink="true" />
              <Label className='text-white'>Gerenciar arquivos</Label>
              <CEAMenuItem title="Remessas" pageRoute="/tarefas/remessas" iconName="DocumentSet" finalLink="true" />
              <CEAMenuItem title="Retornos" pageRoute="/tarefas/retornos" iconName="DocumentReply" finalLink="true" />
            </Stack>
          </Panel>

          <Panel
            isOpen={isOpenRelatorios}
            type={PanelType.smallFixedNear}
            onDismiss={dismissPanelRelatorios}
            isLightDismiss={true}
            isBlocking={true}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText=""
            className="cea-menu-panel"
            style={{ left: "112px", top: "40px", }}
            styles={{
              main: {
                width: "280px",
                backgroundColor: headerColor,
                boxShadow: "none !important",
                borderRight: "1px solid #cabdbd",
                borderLeft: "1px solid rgb(46 46 46)",
                contentInner: {
                  scrollableContent: {
                    content: {
                      paddingLeft: "0px !important",
                      paddingRight: "0px !important"
                    }
                  }
                },
                ".ms-Panel-content": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important"
                },
                ".ms-Panel-commands": {
                  marginTop: "0px",
                  backgroundColor: headerColor,
                },
              }
            }}>
            <Stack vertical>
              <Label className='text-white'>Relatórios</Label>
              <CEAMenuItem title="Transações dos clientes" pageRoute="/transacoes/clientes" iconName="People" finalLink="true" />
              <CEAMenuItem title="Minha carteira" pageRoute="/consulta/carteiras" iconName="Work" finalLink="true" />
              <CEAMenuItem title="Liquidações / Pagamentos" pageRoute="/consulta/liquidacoes" iconName="Money" finalLink="true" />
              <CEAMenuItem title="Extratos" pageRoute="/consulta/extratos" iconName="EntitlementPolicy" finalLink="true" />
            </Stack>
          </Panel>

          <Panel
            isOpen={isOpenTitulos}
            type={PanelType.smallFixedNear}
            onDismiss={dismissPanelTitulos}
            isLightDismiss={true}
            isBlocking={true}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText=""
            className="cea-menu-panel"
            style={{ left: "112px", top: "40px", }}
            styles={{
              main: {
                width: "280px",
                backgroundColor: headerColor,
                boxShadow: "none !important",
                borderRight: "1px solid #cabdbd",
                borderLeft: "1px solid rgb(46 46 46)",
                contentInner: {
                  scrollableContent: {
                    content: {
                      paddingLeft: "0px !important",
                      paddingRight: "0px !important"
                    }
                  }
                },
                ".ms-Panel-content": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important"
                },
                ".ms-Panel-commands": {
                  marginTop: "0px",
                  backgroundColor: headerColor,
                },
              }
            }}>
            <Stack vertical>
              <Label className='text-white'>Consulta</Label>
              <CEAMenuItem title="Todos os títulos" pageRoute="/consulta/titulos=all_geral_0" iconName="Documentation" finalLink="true" />
              {isTransactionGroup && <CEAMenuItem title="Todos os Boletos" pageRoute="/consulta/titulos=bol_geral_0" iconName="GenericScan" finalLink="true" />}
              {isTransactionGroup && <CEAMenuItem title="Todos 'DOC/TED/Transf.'" pageRoute="/consulta/titulos=tra_geral_0" iconName="M365InvoicingLogo" finalLink="true" />}
              {!isTransactionGroup && <div><Label className='text-white'>Consulta por status</Label>
                <CEAMenuItem title="A vencer" pageRoute="/consulta/titulos=all_a-vencer_0" iconName="DateTime" finalLink="true" />
                <CEAMenuItem title="Vencidos" pageRoute="/consulta/titulos=all_vencidos_0" iconName="EventDateMissed12" finalLink="true" />
                <CEAMenuItem title="Enviados (Sem confirm.)" pageRoute="/consulta/titulos=all_enviados_0" iconName="BufferTimeBoth" finalLink="true" />
                <CEAMenuItem title="Transmitidos (Sem registro)" pageRoute="/consulta/titulos=all_transmitidos_0" iconName="TransitionEffect" finalLink="true" /></div>}
              {isTransactionGroup && <div><Label className='text-white'>Boletos por status</Label>
                <CEAMenuItem title="A vencer" pageRoute="/consulta/titulos=bol_a-vencer_0" iconName="DateTime" finalLink="true" />
                <CEAMenuItem title="Vencidos" pageRoute="/consulta/titulos=bol_vencidos_0" iconName="EventDateMissed12" finalLink="true" />
                <CEAMenuItem title="Enviados (Sem confirm.)" pageRoute="/consulta/titulos=bol_enviados_0" iconName="BufferTimeBoth" finalLink="true" />
                <CEAMenuItem title="Transmitidos (Sem registro)" pageRoute="/consulta/titulos=bol_transmitidos_0" iconName="TransitionEffect" finalLink="true" /></div>}
              {isTransactionGroup && <div><Label className='text-white'>DOC/TED/Tr... por status</Label>
                <CEAMenuItem title="A vencer" pageRoute="/consulta/titulos=tra_a-vencer_0" iconName="DateTime" finalLink="true" />
                <CEAMenuItem title="Vencidos" pageRoute="/consulta/titulos=tra_vencidos_0" iconName="EventDateMissed12" finalLink="true" /></div>}
            </Stack>
          </Panel>

          <Panel
            isOpen={isOpenClientes}
            type={PanelType.smallFixedNear}
            onDismiss={dismissPanelClientes}
            isLightDismiss={true}
            isBlocking={true}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText=""
            className="cea-menu-panel"
            style={{ left: "112px", top: "40px", }}
            styles={{
              main: {
                width: "280px",
                backgroundColor: headerColor,
                boxShadow: "none !important",
                borderRight: "1px solid #cabdbd",
                borderLeft: "1px solid rgb(46 46 46)",
                contentInner: {
                  scrollableContent: {
                    content: {
                      paddingLeft: "0px !important",
                      paddingRight: "0px !important"
                    }
                  }
                },
                ".ms-Panel-content": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important"
                },
                ".ms-Panel-commands": {
                  marginTop: "0px",
                  backgroundColor: headerColor,
                },
              }
            }}>
            <Stack vertical>
              <Label className='text-white'>Consulta</Label>
              <CEAMenuItem title="Todos os clientes" pageRoute="/clientes/consulta" iconName="ViewList" finalLink="true" />
              <CEAMenuItem title="Grupos de clientes" pageRoute="/clientes/grupos" iconName="ViewListGroup" finalLink="true" />
              <Label className='text-white'>Portal do cliente</Label>
              <CEAMenuItem title="Solicitações" pageRoute="/clientes/solicitacoes" iconName="Ticket" finalLink="true" />
              <CEAMenuItem title="Logs" pageRoute="/clientes/logs-portal" iconName="History" finalLink="true" />
            </Stack>
          </Panel>

          <Panel
            isOpen={isOpenSistema}
            type={PanelType.smallFixedNear}
            onDismiss={dismissPanelSistema}
            isLightDismiss={true}
            isBlocking={true}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText=""
            className="cea-menu-panel"
            style={{ left: "112px", top: "40px", }}
            styles={{
              main: {
                width: "280px",
                backgroundColor: headerColor,
                boxShadow: "none !important",
                borderRight: "1px solid #cabdbd",
                borderLeft: "1px solid rgb(46 46 46)",
                contentInner: {
                  scrollableContent: {
                    content: {
                      paddingLeft: "0px !important",
                      paddingRight: "0px !important"
                    }
                  }
                },
                ".ms-Panel-content": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important"
                },
                ".ms-Panel-commands": {
                  marginTop: "0px",
                  backgroundColor: headerColor,
                },
              }
            }}>
            <Stack vertical>
              <Label className='text-white'>Atividade</Label>
              <CEAMenuItem title="Diários lançados" pageRoute="/sistema/processamentos" iconName="TaskManager" finalLink="true" />
              <Label className='text-white'>Logs do sistema</Label>
              <CEAMenuItem title="Cobrança ativa" pageRoute="/sistema/logs-cobranca" iconName="PrimaryCalendar" finalLink="true" />
              <CEAMenuItem title="Logs de e-mail" pageRoute="/sistema/logs-email" iconName="MailSchedule" finalLink="true" />
              <CEAMenuItem title="Logs de integração" pageRoute="/sistema/logs-integracao" iconName="BranchPullRequest" finalLink="true" />
              <CEAMenuItem title="Logs da API Itaú" pageRoute="/sistema/logs-integracao-itau" iconName="ChangeEntitlements" finalLink="true" />
            </Stack>
          </Panel>

          <Panel
            isOpen={isOpenContratos}
            type={PanelType.smallFixedNear}
            onDismiss={dismissPanelContratos}
            isLightDismiss={true}
            isBlocking={true}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText=""
            className="cea-menu-panel"
            style={{ left: "112px", top: "40px", }}
            styles={{
              main: {
                width: "280px",
                backgroundColor: headerColor,
                boxShadow: "none !important",
                borderRight: "1px solid #cabdbd",
                borderLeft: "1px solid rgb(46 46 46)",
                contentInner: {
                  scrollableContent: {
                    content: {
                      paddingLeft: "0px !important",
                      paddingRight: "0px !important"
                    }
                  }
                },
                ".ms-Panel-content": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important"
                },
                ".ms-Panel-commands": {
                  marginTop: "0px",
                  backgroundColor: headerColor,
                },
              }
            }}>
            <Stack vertical>
              <Label className='text-white'>Gestão de Contratos</Label>
              <CEAMenuItem title="Cadastro de núcleos" pageRoute="/kantar/nucleos" iconName="TaskManager" finalLink="true" />
              <CEAMenuItem title="Relação de contratos" pageRoute="/kantar/contratos" iconName="TaskManager" finalLink="true" />
              <CEAMenuItem title="Períodos" pageRoute="/kantar/periodos" iconName="TaskManager" finalLink="true" />
              <Label className='text-white'>Configurações</Label>
              <CEAMenuItem title="Mapa de exportação" pageRoute="/kantar/configuracoes/mapa-de-exportacao" iconName="TaskManager" finalLink="true" />
              {/* <CEAMenuItem title="Agendamento" pageRoute="/kantar/configuracoes/agendamento" iconName="TaskManager" finalLink="true" /> */}
            </Stack>
          </Panel>

          <Panel
            isOpen={isOpenConfig}
            type={PanelType.smallFixedNear}
            onDismiss={dismissPanelConfig}
            isLightDismiss={true}
            isBlocking={true}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText=""
            className="cea-menu-panel"
            style={{ left: "112px", top: "40px", }}
            styles={{
              main: {
                width: "280px",
                backgroundColor: headerColor,
                boxShadow: "none !important",
                borderRight: "1px solid #cabdbd",
                borderLeft: "1px solid rgb(46 46 46)",
                contentInner: {
                  scrollableContent: {
                    content: {
                      paddingLeft: "0px !important",
                      paddingRight: "0px !important"
                    }
                  }
                },
                ".ms-Panel-content": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important"
                },
                ".ms-Panel-commands": {
                  marginTop: "0px",
                  backgroundColor: headerColor,
                },
              }
            }}>
            <Stack vertical>
              <Label className='text-white'>Caixa e banco</Label>
              <CEAMenuItem title="Contas bancarias" pageRoute="/configuracoes/contas-bancarias" iconName="Bank" finalLink="true" />
              <CEAMenuItem title="Grupos de método" pageRoute="/configuracoes/grupos-metodo" iconName="EngineeringGroup" finalLink="true" />
              <CEAMenuItem title="Métodos de pagamento" pageRoute="/configuracoes/metodos-pagamento" iconName="Money" finalLink="true" />
              <Label className='text-white'>Integração bancária</Label>
              <CEAMenuItem title="API Itaú" pageRoute="/configuracoes/api-itau" iconName="AzureAPIManagement" finalLink="true" />
              <Label className='text-white'>Parâmetros e configurações</Label>
              {isCalendarFeature &&
                <CEAMenuItem title="Calendário" pageRoute="/configuracoes/calendario" iconName="CalendarSettings" finalLink="true" />}
              <CEAMenuItem title="Boletos" pageRoute="/configuracoes/boletos" iconName="GenericScan" finalLink="true" />
              <CEAMenuItem title="Cedente" pageRoute="/configuracoes/cedente" iconName="CityNext2" finalLink="true" />

              <CEAMenuItem title="Aviso automático" pageRoute="/configuracoes/aviso-automatico" iconName="MailSchedule" finalLink="true" />
              <CEAMenuItem title="Email (SMTP)" pageRoute="/configuracoes/smtp" iconName="MailOptions" finalLink="true" />
              <CEAMenuItem title="FTP" pageRoute="/configuracoes/ftp" iconName="FabricNetworkFolder" finalLink="true" />
              <Label className='text-white'>Segurança</Label>
              <CEAMenuItem title="Meu perfil" pageRoute="/configuracoes/perfil-usuario" iconName="Contact" finalLink="true" />
              <CEAMenuItem title="Organização" pageRoute="/configuracoes/gerenciamento-usuarios" iconName="WorkforceManagement" finalLink="true" />
            </Stack>
          </Panel>

          <Panel
            isOpen={isOpenAdmin}
            type={PanelType.smallFixedNear}
            onDismiss={dismissPanelAdmin}
            isLightDismiss={true}
            isBlocking={true}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText=""
            className="cea-menu-panel"
            style={{ left: "112px", top: "40px", }}
            styles={{
              main: {
                width: "280px",
                backgroundColor: headerColor,
                boxShadow: "none !important",
                borderRight: "1px solid #cabdbd",
                borderLeft: "1px solid rgb(46 46 46)",
                contentInner: {
                  scrollableContent: {
                    content: {
                      paddingLeft: "0px !important",
                      paddingRight: "0px !important"
                    }
                  }
                },
                ".ms-Panel-content": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important"
                },
                ".ms-Panel-commands": {
                  marginTop: "0px",
                  backgroundColor: headerColor,
                },
              }
            }}>
            <Stack vertical>
              <Label className='text-white'>Geral</Label>
              <CEAMenuItem title="Consulta e alterações" pageRoute="/admin/titulos/global" iconName="Globe2" finalLink="true" />
              <CEAMenuItem title="Bloqueados" pageRoute="/admin/titulos/bloqueados" iconName="PageLock" finalLink="true" />
              <CEAMenuItem title="Remessas" pageRoute="/admin/logs-remessas" iconName="DocumentSet" finalLink="true" />
              <CEAMenuItem title="Retornos" pageRoute="/admin/logs-retornos" iconName="DocumentReply" finalLink="true" />
              <Label className='text-white'>Empresas</Label>
              <CEAMenuItem title="Grupos" pageRoute="/admin/empresas/grupos" iconName="HomeGroup" finalLink="true" />
              <CEAMenuItem title="Cedentes" pageRoute="/admin/empresas/cedentes" iconName="CityNext2" finalLink="true" />
              <CEAMenuItem title="Matriz/Filial" pageRoute="/admin/empresas/matriz-filial" iconName="Relationship" finalLink="true" />
              <CEAMenuItem title="Calendário Global" pageRoute="/admin/calendario-global" iconName="CalendarSettings" finalLink="true" />
              <Label className='text-white'>Gerenciamento</Label>
              <CEAMenuItem title="Usuários" pageRoute="/admin/usuarios" iconName="Group" finalLink="true" />
              <CEAMenuItem title="Extrato" pageRoute="/admin/extrato" iconName="WaitlistConfirm" finalLink="true" />
              <Label className='text-white'>Ferramentas</Label>
              <CEAMenuItem title="Diários lançados" pageRoute="/admin/journal-queues" iconName="TaskManager" finalLink="true" />
              <CEAMenuItem title="Logs de acesso" pageRoute="/admin/logs-acesso" iconName="AccountBrowser" finalLink="true" />
              <CEAMenuItem title="Logs de atividade" pageRoute="/admin/logs-atividade" iconName="UserEvent" finalLink="true" />
              <CEAMenuItem title="Logs CEA" pageRoute="/admin/logs-cea" iconName="ProductCatalog" finalLink="true" />
              <CEAMenuItem title="Logs de request" pageRoute="/admin/logs-requests" iconName="BranchPullRequest" finalLink="true" />
              <Label className='text-white'>Portal de boletos</Label>
              <CEAMenuItem title="Usuários" pageRoute="/admin/portal-boletos/usuarios" iconName="FabricUserFolder" finalLink="true" />

            </Stack>
          </Panel>

        </Stack.Item>

        <Stack.Item grow={true} className="cea-contentRegion">
          <Stack className={classNames.contentWrapper}>{children}</Stack>
        </Stack.Item>
      </Stack>


      <Panel
        style={{ marginTop: "40px" }}
        isOpen={painelEmpresas}
        isBlocking={true}
        isLightDismiss
        type={PanelType.smallFixedFar}
        onDismiss={() => setPainelEmpresas(false)}
        closeButtonAriaLabel="Fechar"
        className="cea-usermenu-panel"
        headerText={CEA.displayUsuario()}>

        <p style={{ marginBottom: '0px' }}><Link onClick={() => { goToPage("/configuracoes/perfil-usuario"); setPainelEmpresas(false); }}><Icon iconName="Contact" /> Perfil do usuário</Link></p>
        <p style={{ marginBottom: '0px' }}><Link onClick={logout}><Icon iconName="SignOut" /> Sair do sistema</Link></p>
        <Separator />
        <p style={{ fontSize: "16px", marginTop: 0 }}>Você está logado atualmente em:</p>
        <p style={{ fontWeight: 500, color: "#0F62FE", marginBottom: 0 }}>{`${defaultCedente.Codigo} - ${trim35(defaultCedente.Nome)}`}</p>
        <p style={{ fontWeight: 500, color: "#0F62FE", marginTop: 3 }}>{`${RetornaDocumentoFormatado(defaultCedente.Cedente_CNPJ_CPF)}`}</p>

        <Stack style={{ childrenGap: 20 }}>
          <SearchBox placeholder="Buscar"
            onChange={(events, newValue) => {
              if (newValue === '') {
                setCedentesValues(dropEmpresasFix);
              } else {
                var result = dropEmpresasFix.filter((item) =>
                  Object.keys(item).some(
                    (k) =>
                      item[k] !== null &&
                      item[k].toString().toLowerCase().includes(newValue.toLowerCase())
                  )
                );
                setCedentesValues(result);
              }
            }} />
          <div style={{ marginBottom: 20 }}></div>

          {cedenteOptions.map((drop, i) => {
            if (drop.itemType === 2) {
              return <div key={`dropced-d21-${i}`}></div>;
            }
            else if (drop.itemType === 0) {
              return (<Link href="" key={`link1-${i}`} id={`link1-${i}`} onClick={async () => {
                var loginRequest = await api.post(`auth/repoint/${drop.key}`, null, CEA.headerConfigPost);
                const loginResult = new AuthResponse();
                loginResult.loadData(loginRequest.data);

                login(
                  loginResult.AccessToken,
                  `"${loginResult.getClaim('Cedente').Value.toString()}"`,
                  `"${loginResult.getClaim('TransactionGroup').Value.toString()}"`,
                  `"${loginResult.getClaim('ContractGroup').Value.toString()}"`,
                  `"${loginResult.getClaim('CalendarFeature').Value.toString()}"`,
              );

                const userRequest = await api.get('auth/display');

                const userResult = new UserDisplayData(userRequest.data);
                setUser(userResult);

                const firePoint = RetornaFireDoc(userResult.Cedentes, getAccessPoint());
                setFirePoint(firePoint);

                const powerbi = RetornaPBI(userResult.Cedentes, getAccessPoint());
                setPBI(powerbi);

                var userFilter = { statusCategoria: ['0', '1'], empresas: [getAccessPoint()] };

                setFilters(userFilter);

                document.location.reload();
              }}>
                <div style={{ borderBottom: "1px solid rgba(204, 204, 204, 0.8)", marginBottom: 5 }}>
                  <p style={{ fontWeight: 500, fontSize: 13, marginBottom: 0, marginTop: 0, color: "#323130" }}><i>Grupo {drop.groupName}</i></p>
                  <p style={{ fontSize: 13, marginTop: 0, marginBottom: 0, color: "#323130" }}>{trim35(drop.text)}</p>
                  <p style={{ fontSize: 13, marginTop: 0, marginBottom: 5, color: "#323130" }}><b>{drop.code}</b> {RetornaDocumentoFormatado(drop.key)}</p>
                </div>
              </Link>)
            }
            else {
              return <div key={`dropced-d2-${i}`}></div>;
            }
          })}
        </Stack>
      </Panel>

      <Panel
        style={{ marginTop: "40px" }}
        isOpen={painelNotificacoes}
        isBlocking={true}
        isLightDismiss
        type={PanelType.smallFixedFar}
        onDismiss={() => setPainelNotificacoes(false)}
        closeButtonAriaLabel="Fechar"
        className="cea-usermenu-panel"
        headerText="Notificações">
        <p style={{ fontSize: "16px" }}>Notificações do grupo:</p>
        <Stack style={{ childrenGap: 20 }}>
          <div>
            {pushs.map((item, i) => (
              <ActivityItem
                key={`activityitem1-${i}`}
                id={`activityitem1-${i}`}
                style={{ marginTop: 20 }}
                activityDescription={<div><span style={{ fontWeight: 600, color: '#0F62FE' }}>{item.User_Nome}</span>: <span>{item.Title}</span></div>}
                activityPersonas={[{ imageInitials: item.User_Iniciais, text: item.User_Nome }]}
                comments={item.Message}
                activityIcon={<Icon iconName={item.User_Token} />}
                timeStamp={new Date(item.DataHora).toLocaleString()}>
              </ActivityItem>
            ))}
          </div>
        </Stack>
      </Panel>
    </div>


  );
}

export const MasterLayout = styled(MasterLayoutComponent, getStyles);
