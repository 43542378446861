import { get } from "lodash-es";
import paths from "path";
import React from "react";

import { hierarchize } from "./util/hierarchical";

const keyName = "key";
const pathName = "path";
const uniqueKeyName = "uniqueKey";

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + "." + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, "");
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

function find(searchData, target, accum = []) {
  target.forEach((f) => {
    if (f.children) {
      find(searchData, f.children, accum)
    }
    if (f.path.includes(searchData)) {
      accum.push(f);
    }
  });
  return accum;
}

export function getPathName(pathname) {
  try {
    if (pathname.includes("=")) {
      pathname = pathname.split('=')[0];
    }

    var result = find(pathname, routeConfig.children);
    return result[0].name;
  } catch (error) {
    return "Componente";
  }
}

const routeArray =
{
  key: "home",
  name: "Dashboard",
  icon: "Home",
  path: "/",
  component: React.lazy(() => import("./components/pages/Dashboard")),
  children: [
    {
      key: "homedash",
      name: "Dashboard",
      icon: "home",
      path: "/dashboard",
      component: React.lazy(() => import("./components/pages/Dashboard")),
    },
    {
      key: "dash2",
      name: "Dashboard v2",
      icon: "Home",
      path: "/dashv2",
      component: React.lazy(() => import("./components/pages/DashboardV2")),
    },
    {
      key: "login",
      name: "Entrar",
      isPublic: true,
      isHidden: true,
      component: React.lazy(() => import("./components/pages/00_Seguranca/Login")),
    },
    {
      key: "esqueciSenha",
      name: "Esqueci a senha",
      isPublic: true,
      isHidden: true,
      component: React.lazy(() => import("./components/pages/00_Seguranca/EsqueciSenha")),
    },
    {
      key: "resetpassword",
      name: "Reset Password",
      isPublic: true,
      isHidden: true,
      component: React.lazy(() => import("./components/pages/00_Seguranca/ResetPassword")),
    },
    {
      key: "profile",
      name: "Profile",
      isHidden: true,
    },
    {
      key: "system",
      name: "Sistema",
      icon: "Dictionary",
      children: [
        {
          key: "tarefas",
          name: "Tarefas",
          icon: "TaskGroup",
          children: [
            {
              key: "diario",
              name: "Diário",
              icon: "Dictionary",
              isHidden: true,
              path: "/tarefas/diarios/diario",
              component: React.lazy(() => import("./components/pages/02_Tarefas/Diario")),
            },
            {
              key: "diario-fidc",
              name: "Diário FIDC",
              icon: "Dictionary",
              isHidden: true,
              path: "/tarefas/diarios/diario-fidc",
              component: React.lazy(() => import("./components/pages/02_Tarefas/DiarioFidc")),
            },
            {
              key: "diarios",
              name: "Diários",
              icon: "Dictionary",
              path: "/tarefas/diarios",
              component: React.lazy(() => import("./components/pages/02_Tarefas/Diarios")),
            },
            {
              key: "remessas",
              name: "Remessas",
              icon: "Documentation",
              path: "/tarefas/remessas",
              component: React.lazy(() => import("./components/pages/02_Tarefas/Remessas")),
            },
            {
              key: "retornos",
              name: "Retornos",
              icon: "OpenFile",
              path: "/tarefas/retornos",
              component: React.lazy(() => import("./components/pages/02_Tarefas/Retornos")),
            },
            {
              key: "detalheretorno",
              name: "Datalhe Retorno",
              icon: "OpenFile",
              path: "/tarefas/detalhesretorno",
              component: React.lazy(() => import("./components/pages/07_Detalhes/DetalheRetorno")),
            }
          ]
        },
        {
          key: "transacoes",
          name: "Transaçoes",
          icon: "SearchAndApps",
          children: [
            {
              key: "transacoes_cliente",
              name: "Transaçoes do cliente",
              path: "/transacoes/clientes",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/03_Transacoes/Transacoes_Cliente")),
            },
          ]
        },
        {
          key: "titulos",
          name: "Títulos",
          icon: "SearchAndApps",
          children: [
            {
              key: "geral",
              name: "Consulta de títulos",
              path: "/consulta/titulos",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "geral",
              name: "Consulta de títulos",
              path: "/consulta/titulos=all_geral_:id",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "a-vencer",
              name: "Títulos a vencer",
              path: "/consulta/titulos=all_a-vencer_:id",
              icon: "DateTimeMirrored",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "vencidos",
              name: "Títulos vencidos",
              path: "/consulta/titulos=all_vencidos_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "enviados",
              name: "Títulos sem confirmação",
              path: "/consulta/titulos=all_enviados_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "transmitidos",
              name: "Títulos sem registro",
              path: "/consulta/titulos=all_transmitidos_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "transmitidos",
              name: "Títulos registrados",
              path: "/consulta/titulos=all_registrados_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "geral",
              name: "Consulta de títulos",
              path: "/consulta/titulos=bol_geral_:id",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "a-vencer",
              name: "Títulos a vencer",
              path: "/consulta/titulos=bol_a-vencer_:id",
              icon: "DateTimeMirrored",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "vencidos",
              name: "Títulos vencidos",
              path: "/consulta/titulos=bol_vencidos_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "enviados",
              name: "Títulos sem confirmação",
              path: "/consulta/titulos=bol_enviados_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "transmitidos",
              name: "Títulos sem registro",
              path: "/consulta/titulos=bol_transmitidos_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "geral",
              name: "Consulta de títulos",
              path: "/consulta/titulos=tra_geral_:id",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "a-vencer",
              name: "Títulos a vencer",
              path: "/consulta/titulos=tra_a-vencer_:id",
              icon: "DateTimeMirrored",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "vencidos",
              name: "Títulos vencidos",
              path: "/consulta/titulos=tra_vencidos",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "enviados",
              name: "Títulos sem confirmação",
              path: "/consulta/titulos=tra_enviados_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "transmitidos",
              name: "Títulos sem registro",
              path: "/consulta/titulos=tra_transmitidos_:id",
              icon: "EventDateMissed12",
              component: React.lazy(() => import("./components/pages/03_Consultas/Consulta_Geral")),
            },
            {
              key: "detalhetitulo",
              name: "Datalhe de título",
              icon: "OpenFile",
              path: "/consulta/titulo/detalhe-old",
              component: React.lazy(() => import("./components/pages/07_Detalhes/DetalheTitulo")),
            },
            {
              key: "detalhetitulocarbon",
              name: "Datalhe de título",
              icon: "OpenFile",
              path: "/consulta/titulo/detalhe",
              component: React.lazy(() => import("./components/pages/07_Detalhes/DetalheTituloCarbon")),
            },
            {
              key: "clientes",
              name: "Clientes",
              path: "/consulta/clientes",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/04_Relatorios/Cliente")),
            },
            {
              key: "detalhecliente",
              name: "Datalhe de cliente",
              icon: "OpenFile",
              path: "/consulta/cliente/detalhe",
              component: React.lazy(() => import("./components/pages/07_Detalhes/DetalheCliente")),
            },
            {
              key: "carteira",
              name: "Minha carteira",
              path: "/consulta/carteiras",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/04_Relatorios/Carteira")),
            },
            {
              key: "liquidacoes",
              name: "Relatório de liquidações",
              path: "/consulta/liquidacoes",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/04_Relatorios/Liquidacoes")),
            },
            {
              key: "emissoes",
              name: "Emissões",
              path: "/consulta/emissoes",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/04_Relatorios/Emissoes")),
            },
            {
              key: "extratos",
              name: "Relatório de extratos",
              path: "/consulta/extratos",
              icon: "BulletedListText",
              component: React.lazy(() => import("./components/pages/04_Relatorios/Extratos")),
            },
            {
              key: "detalhecarteira",
              name: "Datalhe de carteira",
              icon: "OpenFile",
              path: "/consulta/carteira/detalhe",
              component: React.lazy(() => import("./components/pages/07_Detalhes/DetalheCarteira")),
            },
          ]
        },
        {
          key: "logs",
          name: "Logs",
          icon: "ProductCatalog",
          children: [
            {
              key: "logs-transacao",
              name: "Logs de transação",
              icon: "Timeline"
            },
            {
              key: "logs-email",
              name: "Logs de e-mail",
              icon: "MailSchedule",
              path: "/sistema/logs-email",
              component: React.lazy(() => import("./components/pages/05_Sistema/LogsEmail")),
            },
          ]
        }
      ],
    },
    {
      key: "clientes",
      name: "Clientes",
      icon: "",
      children: [
        {
          key: "consulta-clientes",
          name: `Consulta de clientes`,
          icon: "ServerProcesses",
          path: "/clientes/consulta",
          component: React.lazy(() => import("./components/pages/10_Clientes/ConsultaClientes")),
        },
        {
          key: "detalhes-cliente",
          name: `Detalhes do cliente`,
          icon: "ServerProcesses",
          path: "/clientes/detalhe",
          component: React.lazy(() => import("./components/pages/10_Clientes/DetalhesCliente")),
        },
        {
          key: "consulta-grupo-clientes",
          name: `Consulta Grupo de clientes`,
          icon: "ServerProcesses",
          path: "/clientes/grupos",
          component: React.lazy(() => import("./components/pages/10_Clientes/ConsultaGrupos")),
        },
        {
          key: "detalhes-grupo-cliente",
          name: `Detalhe do grupo de clientes`,
          icon: "ServerProcesses",
          path: "/clientes/grupos-detalhe",
          component: React.lazy(() => import("./components/pages/10_Clientes/DetalhesGrupo")),
        },
        {
          key: "consulta-solicitacoes-clientes",
          name: `Solicitações de clientes`,
          icon: "ServerProcesses",
          path: "/clientes/solicitacoes",
          component: React.lazy(() => import("./components/pages/10_Clientes/ConsultaSolicitacoes")),
        },
        {
          key: "consulta-usuarios-clientes",
          name: `Usuários do portal`,
          icon: "ServerProcesses",
          path: "/clientes/usuarios",
          component: React.lazy(() => import("./components/pages/10_Clientes/UsuariosPortal")),
        },
        {
          key: "consulta-logs-portal",
          name: `Logs do portal`,
          icon: "ServerProcesses",
          path: "/clientes/logs-portal",
          component: React.lazy(() => import("./components/pages/10_Clientes/LogsPortal")),
        },
      ]
    },
    {
      key: "sistema",
      name: "Sistema",
      icon: "",
      children: [
        {
          key: "queueRemessas",
          name: `Diário lançados`,
          icon: "ServerProcesses",
          path: "/sistema/processamentos",
          component: React.lazy(() => import("./components/pages/05_Sistema/Processamentos")),
        },
        {
          key: "logsIntegracao",
          name: `Logs de integração`,
          icon: "ServerProcesses",
          path: "/sistema/logs-integracao",
          component: React.lazy(() => import("./components/pages/05_Sistema/LogsIntegracao")),
        },
        {
          key: "logsIntegracaoItau",
          name: `Logs da API Itaú`,
          icon: "ServerProcesses",
          path: "/sistema/logs-integracao-itau",
          component: React.lazy(() => import("./components/pages/05_Sistema/LogsIntegracaoItau")),
        },
        {
          key: "logsCobranca",
          name: `Logs cobrança`,
          icon: "ServerProcesses",
          path: "/sistema/logs-cobranca",
          component: React.lazy(() => import("./components/pages/05_Sistema/LogsCobranca")),
        }
      ]
    },
    {
      key: "contratos",
      name: "Contratos",
      icon: "",
      children: [
        {
          key: "nucleos",
          name: `Núcleos`,
          icon: "ServerProcesses",
          path: "/kantar/nucleos",
          component: React.lazy(() => import("./components/pages/09_Kantar/Nucleos")),
        },
        {
          key: "contratos",
          name: `Contratos`,
          icon: "ServerProcesses",
          path: "/kantar/contratos",
          component: React.lazy(() => import("./components/pages/09_Kantar/Contratos")),
        },
        {
          key: "periodos",
          name: `Períodos`,
          icon: "ServerProcesses",
          path: "/kantar/periodos",
          component: React.lazy(() => import("./components/pages/09_Kantar/Periodos")),
        },
        {
          key: "kConfiguracoes",
          name: "Configurações",
          icon: "Settings",
          children: [
            {
              key: "kMapaDeExportacao",
              name: `Mapa de exportação`,
              icon: "ServerProcesses",
              path: "/kantar/configuracoes/mapa-de-exportacao",
              component: React.lazy(() => import("./components/pages/09_Kantar/Configuracoes/MapaDeExportacao")),
            },
            {
              key: "kAgendamento",
              name: `Agendamento`,
              icon: "ServerProcesses",
              path: "/kantar/configuracoes/agendamento",
              component: React.lazy(() => import("./components/pages/09_Kantar/Configuracoes/Agendamento")),
            },
          ]
        }
      ]
    },
    {
      key: "configuracaoes",
      name: "Configurações",
      children: [
        {
          key: "caixaebanco",
          name: "Caixa e banco",
          icon: "Bank",
          children: [
            {
              key: "contas-bancarias",
              name: "Contas bancarias",
              path: "/configuracoes/contas-bancarias",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/ContaBancaria")),
            },
            {
              key: "grupo-metodo",
              name: "Grupos de método",
              path: "/configuracoes/grupos-metodo",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/GrupoDeMetodo")),
            },
            {
              key: "metodos-pagamento",
              name: "Métodos",
              path: "/configuracoes/metodos-pagamento",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/MetodoPagamento")),
            },
            {
              key: "api-itau",
              name: "API Itaú",
              path: "/configuracoes/api-itau",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/ItauAPIConfig")),
            },
          ],
        },
        {
          key: "outros",
          name: "Parametros",
          icon: "DataManagementSettings",
          children: [
            {
              key: "cedente",
              name: "Cedente",
              icon: "SecurityGroup",
              path: "/configuracoes/cedente",
              component: React.lazy(() => import("./components/pages/07_Detalhes/DetalheCedente")),
            },
            {
              key: "calendario",
              name: "Calendário",
              icon: "CalendarSettings",
              path: "/configuracoes/calendario",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/CalendarioCedente/CalendarioCedente")),
            },
            {
              key: "boleto",
              name: "Boleto",
              icon: "CalendarSettings",
              path: "/configuracoes/boletos",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/BoletoConfig/BoletoConfig")),
            },
            {
              key: "email-smtp",
              name: "Configurações de SMTP",
              icon: "SecurityGroup",
              path: "/configuracoes/smtp",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/SMTP")),
            },
            {
              key: "ftpConfig",
              name: "Configurações de FTP",
              icon: "CalendarSettings",
              path: "/configuracoes/ftp",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/FtpConfig/FtpConfig")),
            },
            {
              key: "sequencias",
              name: "Sequências",
              icon: "SecurityGroup",
            },
            {
              key: "email-aviso-atumatico",
              name: "Aviso automático",
              icon: "SecurityGroup",
              path: "/configuracoes/aviso-automatico",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/AvisoAutomatico")),
            },
            {
              key: "perfil-usuario",
              name: "Perfil do usuário",
              icon: "SecurityGroup",
              path: "/configuracoes/perfil-usuario",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/Usuario")),
            },
            {
              key: "gerenciamento-usuarios",
              name: "Gerenciamento de usuários",
              icon: "SecurityGroup",
              path: "/configuracoes/gerenciamento-usuarios",
              component: React.lazy(() => import("./components/pages/06_Configuracoes/GerenciamentoUsuarios")),
            },
          ],
        },
      ],
    },
    {
      key: "suporte",
      name: "Suporte",
      children: [
        {
          key: "suporteConsulta",
          name: "consulta",
          icon: "Bank",
          children: [
            {
              key: "consulta-alteracoes",
              name: "Suporete: Consulta e alterações",
              path: "/suporte/consulta",
              component: React.lazy(() => import("./components/pages/08_Suporte/Consulta_Alteracoes")),
            },
          ]
        }
      ]
    },
    {
      key: "administracao",
      name: "Administração",
      children: [
        {
          key: "admintitulos",
          name: "titulos",
          icon: "Bank",
          children: [
            {
              key: "consulta-global",
              name: "Consulta global",
              path: "/admin/titulos/global",
              component: React.lazy(() => import("./components/pages/01_Admin/Consulta_Global")),
            },
            {
              key: "consulta-bloequeados",
              name: "Consulta bloqueados",
              path: "/admin/titulos/bloqueados",
              component: React.lazy(() => import("./components/pages/01_Admin/Consulta_Bloqueados")),
            }
          ],
        },
        {
          key: "adminCedente",
          name: "Empresas",
          icon: "DataManagementSettings",
          children: [
            {
              key: "adminGrupos",
              name: "Grupos",
              icon: "SecurityGroup",
              path: "/admin/empresas/grupos",
              component: React.lazy(() => import("./components/pages/01_Admin/GrupoDeCedente/Grupo_Lista")),
            },
            {
              key: "adminCedentes",
              name: "Cedentes",
              icon: "SecurityGroup",
              path: "/admin/empresas/cedentes",
              component: React.lazy(() => import("./components/pages/01_Admin/Cedentes/Cedentes")),
            },
            {
              key: "adminMatrizFilial",
              name: "Matriz/Filial",
              icon: "SecurityGroup",
              path: "/admin/empresas/matriz-filial",
              component: React.lazy(() => import("./components/pages/01_Admin/MatrizFilial/MatrizFilial")),
            },
            {
              key: "adminCalendario",
              name: "Calendário Global",
              icon: "CalendarSettings",
              path: "/admin/calendario-global",
              component: React.lazy(() => import("./components/pages/01_Admin/CalendarioGlobal/CalendarioGlobal")),
            },
            {
              key: "adminUsuarios",
              name: "Usuários",
              icon: "SecurityGroup",
              path: "/admin/usuarios",
              component: React.lazy(() => import("./components/pages/01_Admin/Usuarios/Usuarios")),
            },
            {
              key: "adminUsuarios",
              name: "Usuários",
              icon: "SecurityGroup",
              path: "/admin/portal-boletos/usuarios",
              component: React.lazy(() => import("./components/pages/01_Admin/UsuariosPortalBoletos/UsuariosPortalBoletos")),
            },
            {
              key: "adminExtrato",
              name: "Extrato",
              icon: "SecurityGroup",
              path: "/admin/extrato",
              component: React.lazy(() => import("./components/pages/01_Admin/Extrato/Extrato")),
            },
            {
              key: "adminLogsCEA",
              name: "Logs CEA",
              icon: "SecurityGroup",
              path: "/admin/logs-cea",
              component: React.lazy(() => import("./components/pages/01_Admin/LogsCEA/LogsCEA")),
            },
            {
              key: "adminLogsRequests",
              name: "Logs Requests",
              icon: "SecurityGroup",
              path: "/admin/logs-requests",
              component: React.lazy(() => import("./components/pages/01_Admin/LogsRequests/LogsRequests")),
            },
            {
              key: "adminQueues",
              name: "Fila de processamento",
              icon: "SecurityGroup",
              path: "/admin/queues",
              component: React.lazy(() => import("./components/pages/01_Admin/Consulta_Processamentos/Consulta_Processamentos")),
            },
            {
              key: "adminJournalQueues",
              name: "Diários lançados",
              icon: "SecurityGroup",
              path: "/admin/journal-queues",
              component: React.lazy(() => import("./components/pages/01_Admin/Consulta_DiariosLancados/Consulta_DiariosLancados")),
            },
            {
              key: "logsAcesso",
              name: `Logs de acesso`,
              icon: "ServerProcesses",
              path: "/admin/logs-acesso",
              component: React.lazy(() => import("./components/pages/01_Admin/LogsAcesso/LogsAcesso")),
            },
            {
              key: "logsAtividade",
              name: `Logs de atividade`,
              icon: "ServerProcesses",
              path: "/admin/logs-atividade",
              component: React.lazy(() => import("./components/pages/01_Admin/LogsAtividade/LogsAtividade")),
            },
            {
              key: "logsRemessas",
              name: `Logs de remessas`,
              icon: "ServerProcesses",
              path: "/admin/logs-remessas",
              component: React.lazy(() => import("./components/pages/01_Admin/LogsRemessas/LogsRemessas")),
            },
            {
              key: "logsRetornos",
              name: `Logs de retornos`,
              icon: "ServerProcesses",
              path: "/admin/logs-retornos",
              component: React.lazy(() => import("./components/pages/01_Admin/LogsRetornos/LogsRetornos")),
            }
          ],
        },
      ],
    },
  ],
}

const routeConfig = hierarchize(
  routeArray,
  null,
  pathGenerator
);

export default routeConfig;
