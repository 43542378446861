export const TOKEN_KEY = "@cea-Token";
export const USER_DATA_KEY = "@cea-User";
export const EMPRESAS_DATA_KEY = "@cea-Empresas";
export const EMPRESA_CLAIM = "@cea-AccessPoint";
export const TRANSACTION_GROUP = "@cea-TransactionGroup";
export const CALENDAR_FEATURE = "@cea-CalendarFeature";
export const CONTRACT_GROUP = "@cea-ContractGroup";
export const CONSULT_FILTERPANEL = "@cea-ConsultFilterPanel";
export const CONSULT_FILTER = "@cea-ConsultFilter";
export const FIRE_CLAIM = "@cea-FirePoint";
export const POWER_CLAIM = "@cea-PWB";
export const THEME = "theme";
export const DROPEMPRESAS_DATA_KEY = "@cea-DropEmpresas";

export const getLocalTheme = () => localStorage.getItem(THEME);
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setFilters = (value) => {
  localStorage.setItem(CONSULT_FILTER, JSON.stringify(value));
};

export const getFilters = () => {
  return JSON.parse(localStorage.getItem(CONSULT_FILTER));
};

export const setFilterPanel = (value) => {
  localStorage.setItem(CONSULT_FILTERPANEL, value);
};

export const getFilterPanel = () => {
  return JSON.parse(localStorage.getItem(CONSULT_FILTERPANEL));
};

export const getDropEmpresas = () => {
  return JSON.parse(localStorage.getItem(USER_DATA_KEY)).Cedentes;
};

export const getGrupoCedenteId = () => {
  var cedentes = getDropEmpresas();
  var cnpj = getAccessPoint().toString();
  var entity = cedentes.filter((cd) => cd.Cedente_CNPJ_CPF === cnpj.toString())[0];
  return entity.GrupoDeCedente_ID;
};

export const setFirePoint = (fire) => {
  localStorage.setItem(FIRE_CLAIM, fire);
};

export const getFirePoint = () => {
  return localStorage.getItem(FIRE_CLAIM);
};

export const setPBI = (fire) => {
  localStorage.setItem(POWER_CLAIM, fire);
};

export const getPBI = () => {
  return localStorage.getItem(POWER_CLAIM);
};

export const getCalendarFeature = () => {
  try {
    const result = JSON.parse(localStorage.getItem(CALENDAR_FEATURE));
    return result === "1";
  }
  catch (err) {
    return false;
  }
};

export const getTransactionGroup = () => {
  const result = JSON.parse(localStorage.getItem(TRANSACTION_GROUP));
  return result === "1";
};

export const getContractGroup = () => {
  const result = JSON.parse(localStorage.getItem(CONTRACT_GROUP));
  return result === "1";
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem(USER_DATA_KEY));
};

export const getAccessPoint = () => {
  const value = JSON.parse(localStorage.getItem(EMPRESA_CLAIM));
  return `${value}`;
};

export const setEmpresa = data => {
  var usrData = [];
  usrData.push(JSON.parse(localStorage.getItem(USER_DATA_KEY)));
  usrData[0].CedenteDefault = data;
  localStorage.setItem(USER_DATA_KEY, JSON.stringify(usrData[0]));
};

export const login = (token, claim, transgroup, contractgroup, calendarFeature) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(EMPRESA_CLAIM, `${claim}`);
  localStorage.setItem(TRANSACTION_GROUP, `${transgroup}`);
  localStorage.setItem(CONTRACT_GROUP, `${contractgroup}`);
  localStorage.setItem(CALENDAR_FEATURE, `${calendarFeature}`);
};

export const logoff = () => {
  localStorage.clear();
};

export const setUser = data => {
  localStorage.setItem(USER_DATA_KEY, JSON.stringify(data));
};

export const storeEmpresas = data => {
  localStorage.setItem(EMPRESAS_DATA_KEY, JSON.stringify(data));
};

export const storeDropEmpresas = data => {
  localStorage.setItem(DROPEMPRESAS_DATA_KEY, JSON.stringify(data));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const RetornaFireDoc = (array, cnpj) => {
  let result = "";
  array.forEach((item) => {
    if (item['Cedente_CNPJ_CPF'] === cnpj) {
      result = item['FireDocument'];
    }
  });
  return result;
};

export const RetornaPBI = (array, cnpj) => {
  let result = "";
  array.forEach((item) => {
    if (item['Cedente_CNPJ_CPF'] === cnpj) {
      result = item['PowerBi'];
    }
  });
  return result;
};