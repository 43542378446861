import { UsuarioCedenteResult } from "./UsuarioCedenteResult";

export class UserDisplayData {
    Id!: string;
    Email!: string;
    Name!: string;
    Surname!: string;
    Initials!: string;
    Sigma!: string;
    Cedentes!: UsuarioCedenteResult[];

    constructor(response: UserDisplayData) {
        this.Id = response.Id;
        this.Email = response.Email;
        this.Name = response.Name;
        this.Surname = response.Surname;
        this.Initials = response.Initials;
        this.Sigma = response.Sigma;
        this.Cedentes = response.Cedentes;
    }
}
