import React from 'react';
import { useAuthentication } from '../util/authentication';
import { BlankLayout } from './BlankLayout';
import { MasterLayout } from './MasterLayout';

export function AutoSwitchLayout({ children }) {
  const { isAuthenticated } = useAuthentication();
  const Layout = isAuthenticated ? MasterLayout : BlankLayout;

  return <Layout>{children}</Layout>;
}
