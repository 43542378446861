import React from 'react';
import { useLocation } from 'react-router-dom';

export default function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>404, página não encontrada</h3>
      <p>
        A página requisitada <code>{location.pathname}</code> não foi encontrada neste servidor.
      </p>
    </div>
  );
}
