import { addVariants } from '@uifabric/variants';
import { createTheme } from 'office-ui-fabric-react';
export var CEATheme = createTheme({
    palette: {
        themePrimary: '#0f62fe',
        themeLighterAlt: '#f7f3fb',
        themeLighter: '#dfd0ef',
        themeLight: '#c5abe2',
        themeTertiary: '#9265c5',
        themeSecondary: '#6931aa',
        themeDarkAlt: '#0353e9',
        themeDark: '#0353e9',
        themeDarker: '#0353e9',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
    semanticColors: {
        buttonBackground: 'transparent',
        buttonBackgroundHovered: '#bdbdbd',
        buttonBackgroundPressed: '#a7a7a7',
        buttonText: '#252424',
        buttonTextPressed: '#252424',
        buttonTextHovered: '#252424',
        buttonBorder: '#bdbdbd',
    },
});
addVariants(CEATheme);
export var CEACustomizations = {
    settings: {
        theme: CEATheme,
    },
    scopedSettings: {},
};